/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Grid, Card, Icon, useMediaQuery, CircularProgress } from "@mui/material";
import {  useParams } from 'react-router-dom'
import { Context } from "contexts/contextState";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useSoftUIController } from "components/layouts/context";
import BaseLayout from 'components/layouts/pages/account/components/BaseLayout';
import { errorAlert, successAlert } from '../components/Alerts';
import { NavigateBack } from '../components/NavigateBack';
import YouTubePlayer from 'react-player/youtube';

export function Course(props) {
  /****************** hooks ********************/
  const params = useParams();
  const { context, setContext } = useContext(Context);
  const isMobileView = useMediaQuery('(max-width:600px)');
  const [controller] = useSoftUIController();
  const { sidenavColor } = controller;

  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [courseData, setCourseData] = useState({});

  const price = useMemo(() => courseData?.currency && courseData?.price
    ? context.formatterService.formatCurrency(courseData.currency?.value || "cad", courseData.price || "")
    : "", [courseData?.currency, courseData?.price])

  /****************** functions ********************/

  async function handlePageLoader() {
    try {
      const courseResponse = await context.courseRepository.findCourseById(params.id)
  
      if (courseResponse) {
        setCourseData(courseResponse)
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setFirstPageLoader(false);
    }
  }

  async function handlePurchase() {
    //check if user is logged
    const isUnlogged = context.user;
    if (!isUnlogged) {
      return errorAlert("Error!", "In order to make a purchase you need to login or register!");
    }
    //check if course is already in cart
    const isCartDuplicate = context.cartService.findCourseFromCart(context.user?.uid,courseData?.id)?.courseId === courseData?.id;
    if (isCartDuplicate) {
      return errorAlert("Error!", "The selected course is already added to your cart!");
    }
    //check if course is already purchased
    const { purchases } = await context.userRepository.findUserById(context?.user?.uid);
    const isAlreadyPurchased = purchases?.find(course => course === courseData?.id);
    if (isAlreadyPurchased) {
      return errorAlert("Error!", "The selected course is already purchased!");
    }
    //add course to the cart
    context.cartService.addCourseToCart(context?.user?.uid, courseData?.id);
    const isAlreadyInCart = context.cart.find(course => course?.courseId === courseData?.id);
    if (!isAlreadyInCart) {
      setContext({ cart: [...context.cart, { courseId: courseData?.id }] })
    }
    return successAlert('Success!', "The selected course was successfully added to your shopping cart!")
  }

  /****************** useEffects ********************/
  
  useEffect(() => {
    handlePageLoader();
  }, []);

  return ( 
    <BaseLayout stickyNavbar>
      <SoftBox mt={-3} ml={2} mb={3}>
        <NavigateBack link={'/courses'} />
      </SoftBox>
      <SoftBox mb={2}>
      {firstPageLoader ? (
        <Card>
          <SoftBox
            display={'flex'}
            height={500}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <CircularProgress color={sidenavColor} />
          </SoftBox>
        </Card>
      ) : (
        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={9} lg={9}>
            <Card sx={{ margin: isMobileView ? 0 : 2, mt: 0, mb: isMobileView ? 2 : 0 }}>
              <SoftBox p={3}>
                <SoftBox p={3}>
                  <SoftTypography variant="h5" gutterBottom>
                    {courseData?.title}
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={3}>
                  {context.formatterService.formatStringAsHtml(courseData?.description)}
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Card sx={{ padding: 2 }}>
              <SoftBox sx={{ borderRadius: 2 }} mb={2}>
                <YouTubePlayer width={'100%'} url={courseData?.youtubeLink}/>
              </SoftBox>
              <SoftBox p={1} sx={{ borderBottom: '1px solid lightgrey', display: 'flex', alignItems: 'center' }}>
                {/* <Icon sx={{ fontWeight: "bold", marginRight: 1}}>attach_money</Icon> */}
                <SoftTypography variant="body2" component="p" color="text">
                  Price:
                </SoftTypography>
                <SoftTypography ml={'auto'} variant="body2" component="p" color="error">
                  {price}
                </SoftTypography>
              </SoftBox>
              <SoftButton
                color={sidenavColor}
                sx={{ padding: 2, fontSize: 14, marginTop: 4, marginBottom: 4 }}
                onClick={handlePurchase}
              >
                Add to Cart
                <Icon sx={{ fontWeight: "bold", ml: 1 }}>arrow_forward</Icon>
              </SoftButton>
            </Card>
          </Grid>
        </Grid>
      )}
      </SoftBox>
    </BaseLayout>
  )
}
