/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card, CircularProgress } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useSoftUIController } from "components/layouts/context";
import { Context } from "contexts/contextState";
import BaseLayout from 'components/layouts/pages/account/components/BaseLayout';
import { WhatsAppWidget } from './components/WhatsAppWidget';
import { CustomHeader } from '../components/CustomHeader';
import { CourseCard } from './components/CourseCard';

export function AllCourses() {
  /****************** hooks ********************/
  const [controller] = useSoftUIController();
  const { sidenavColor } = controller;
  const { context } = useContext(Context);
  
  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [courses, setCourses] = useState([]);

  /****************** functions ********************/
  async function handlePageLoader() {
    try {
      const [courseResponse, imagesResponse] = await Promise.all([
        context.courseRepository.findAllCourse(),
        context.fileStorageRepository.findAllImages()
      ]);
  
      if (courseResponse && imagesResponse) {
        setCourses(courseResponse.map(course => ({
          ...course,
          product: [course.title, imagesResponse.find(image => image.id == course.thumbnailReference?.id)?.fileUrl ],
          level: course.courseLevel.label,
          price: course.price,
          active: course.active,
        })))
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setFirstPageLoader(false);
    }
  }

  /****************** useEffects ********************/
  useEffect(() => {
    handlePageLoader();
  }, []);

  return (
    <BaseLayout stickyNavbar>
      <CustomHeader title="Learn to make Great Cakes!" description="Our mission is to give you the opportunity to bake your dream cake - yourself!" />
      <SoftBox mt={{ xs: 1, lg: 3 }} mb={1} pt={5} pb={2}>
      {firstPageLoader ? (
        <Card>
          <SoftBox
            display={'flex'}
            height={500}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <CircularProgress color={sidenavColor} />
          </SoftBox>
        </Card>
      ) : (
        courses.length ? (
          <Grid container spacing={3}>
            {courses.map((course, index) => {
              return (
                <Grid item xs={12} md={4} lg={3} key={course?.id}>
                  <CourseCard course={course} />
                </Grid>
              )
            })}
          </Grid>
        ) : (
          <SoftBox sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <SoftTypography mb={2} variant='h4'>There are no videos available at the moment</SoftTypography>
          </SoftBox>
        ))}
      </SoftBox>
      <WhatsAppWidget />
    </BaseLayout>
  );
}
