import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { StripePaymentButton } from "views/cart/components/StripePaymentButton";
import { errorAlert } from "views/components/Alerts";
import { useState } from "react";

export function ConfirmPurchaseModal(props) {
  const [isClicked, setIsClicked] = useState(false);
  
  return (
    <Dialog open={props?.open} onClose={props?.onClose}>
      <DialogTitle>
        <SoftBox mx="16px" mt="16px">
          <SoftTypography variant="h3">Confirming purchase action</SoftTypography>
        </SoftBox>
      </DialogTitle>
      <DialogContent>
        <SoftBox mx="16px" mt="16px">
          {isClicked ? (
            <SoftTypography variant="h5">
              {`Your order has ${props?.quantity} ${props?.quantity > 1 ? 'courses' : 'course'} totaling ${props?.displayingPrice}.`}
            </SoftTypography>
          ) : (
            <SoftTypography variant="h5">
              {`Are you sure you wish to create an order with ${props?.quantity} ${props?.quantity > 1 ? 'courses' : 'course'} totaling ${props?.displayingPrice}?`}
            </SoftTypography>
          )}
        </SoftBox>
      </DialogContent>

      <DialogActions>
        <SoftBox display="flex" alignItems="center" justifyContent="center" flex={1} mb={2}>
          <StripePaymentButton
            amount={props.price}
            onSuccess={props.onConfirm}
            onClick={() => setIsClicked(previous => !previous)}
            onError={() => errorAlert("Failed", "opps")}
          />
        </SoftBox>
      </DialogActions>
    </Dialog>
  )
}

ConfirmPurchaseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  displayingPrice: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};