export class CartService {
  /**
   * Retrieves a course by user and course IDs from the localStorage.
   * 
   * @param {string} userId The ID of the user to retrieve.
   * @param {string} courseId The ID of the course to retrieve.
   * 
   * @returns {void}
   */
  addCourseToCart(userId, courseId) {
    const cartCourses = this.findAllCoursesFromCart(userId);
    if (cartCourses?.length) {
      const isExist = cartCourses.find(course => course.courseId === courseId);
      if (!isExist) {
        cartCourses.push({ courseId });
        localStorage.setItem(userId, JSON.stringify(cartCourses));
      }
    } else {
      localStorage.setItem(userId, JSON.stringify([{ courseId }]));
    }
  }

  /**
   * Remove a course by user and course IDs from the localStorage.
   * 
   * @param {string} userId The ID of the user to retrieve.
   * @param {string} courseId The ID of the course to retrieve.
   * 
   * @returns {void}
   */
  removeCourseFromCart(userId, courseId) {
    const cartCourses = this.findAllCoursesFromCart(userId);
    if (cartCourses?.length) {
      const updatedCartCourses = cartCourses.filter(course => course.courseId !== courseId);
      localStorage.setItem(userId, JSON.stringify(updatedCartCourses));
    }
  }

  /**
   * Fetch all courses by user ID from the localStorage.
   * 
   * @param {string} userId The ID of the user to retrieve.
   * 
   * @returns {{ courseId: string }[] | []}
   */
  findAllCoursesFromCart(userId) {
    const courses = JSON.parse(localStorage.getItem(userId));
    return courses || [];
  }

  /**
   * Fetch a specific course by user and course IDs from the localStorage.
   * 
   * @param {string} userId The ID of the user to retrieve.
   * @param {string} courseId The ID of the course to retrieve.
   * 
   * @returns {{ courseId: string } | null}
   */
  findCourseFromCart(userId, courseId) {
    const cartCourses = this.findAllCoursesFromCart(userId);
    return cartCourses.find(course => course?.courseId === courseId) || null;
  }
}
