import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IllustrationLayout from "components/layouts/authentication/components/IllustrationLayout";
import rocket from "assets/images/illustrations/rocket-white.png";
import heroSignup from "assets/images/hero-signup.png";
import { Context } from "contexts/contextState";
import { errorAlert, successAlert } from "./components/Alerts";
import { User } from "models/User";

export function SignUpView() {
  const navigate = useNavigate();
  const { context } = useContext(Context);
  const [agreement, setAgreemnet] = useState(true);
  const [account, setAccount] = useState(new User());
  const [isLoading, setIsLoading] = useState(false);

  function handleAccountInputValue(event) {
    if (event.target.name == "phone") {
      setAccount(previous => ({ ...previous, [event.target.name]: String(event.target.value).replace(/\D/g, '') }));
    } else if (event.target.name == "email") {
      setAccount(previous => ({ ...previous, [event.target.name]: String(event.target.value).toLocaleLowerCase() }));
    } else {
      setAccount(previous => ({ ...previous, [event.target.name]: event.target.value }));
    }
  }

  async function handleCreateAccountWithEmailAndPassword(event) {
    if(!context.validatorService.isEmail(account.email)) {
      return errorAlert("Invalid Email", "Please enter a valid email address.");
    } else if (!context.validatorService.isMobilePhone("+" + account.phone)) {
      return errorAlert("Invalid Phone Number", "Please enter a valid phone number for the United States or Canada.");
    } else if (!context.validatorService.isStrongPassword(account.password)) {
      return errorAlert("Error!", "Password must be at least 6 characters long");
    } else {
      setIsLoading(true);
      try {
        const response = await context.authenticationService.createAccount(account.email, account.password);
        console.log(handleCreateAccountWithEmailAndPassword.name, response);
  
        // create if the account collection was created in the firebase to update the data
        const checkAccountCreated = async (counter = 10) => {
          if (counter < 0) return null;
          await new Promise(resolve => setTimeout(() => resolve(true), 1000));
          const accountResponse = await context.userRepository.findUserById(response.user.uid);
          return Promise.resolve(accountResponse ? accountResponse : checkAccountCreated(counter - 1));
        }
        const updateAccount = await checkAccountCreated();
        if (updateAccount) {
          updateAccount.active = true;
          updateAccount.firstName = account.firstName;
          updateAccount.lastName = account.lastName;
          updateAccount.phone = account.phone;
          context.userRepository.saveUser(updateAccount);
          successAlert("Success!", "Your account has been created.").then(() => {
            navigate("/");
          });
        }
      } catch (error) {
        console.error(handleCreateAccountWithEmailAndPassword.name, error);
      }
      setIsLoading(false);
    }
  }

  return (
    <IllustrationLayout
      title="Cakes By Sim"
      color="white"
      illustration={{ image: heroSignup }}
      // title="Sign Up"
      // description="Enter your email and password to register"
      // illustration={{
      //   image: rocket,
      //   title: "Your journey starts here",
      //   description:
      //     "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
      // }}
    >
      <SoftTypography variant="h5" mt={1} mb={1}>Sign Up</SoftTypography>
      <SoftTypography variant="body2" mb={2}>Enter your data to register</SoftTypography>
      <SoftBox component="form" role="form">
        <SoftBox mb={2} display="flex" gap={2}>
          <SoftInput placeholder="First Name" size="large" name="firstName" onChange={handleAccountInputValue} />
          <SoftInput placeholder="Last Name" size="large" name="lastName" onChange={handleAccountInputValue} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput placeholder="Phone" size="large" name="phone" value={account.phone} onChange={handleAccountInputValue} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" value={account.email} name="email" onChange={handleAccountInputValue} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large" name="password" onChange={handleAccountInputValue} />
        </SoftBox>
        {/* <SoftBox display="flex" alignItems="center">
          <Checkbox checked={agreement} onChange={() => setAgreemnet(!agreement)} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={() => setAgreemnet(!agreement)}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;I agree the&nbsp;
          </SoftTypography>
          <SoftTypography component="a" href="#" variant="button" fontWeight="bold" textGradient>
            Terms and Conditions
          </SoftTypography>
        </SoftBox> */}
        <SoftBox mt={4} mb={1}>
          <SoftButton 
            variant="gradient" color="info" size="large" fullWidth
            disabled={isLoading}
            onClick={handleCreateAccountWithEmailAndPassword}>
            sign up
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Already have an account?&nbsp;
            <SoftTypography
              component={Link}
              to="/authentication/sign-in"
              variant="button"
              color="info"
              fontWeight="bold"
              textGradient
            >
              Sign in
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}
