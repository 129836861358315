import { AuthenticationService } from "services/firebase/authentication/AuthenticationService";
import { createContext } from "react"
import { UserRepository } from "services/firebase/firestore/UserRepository";
import { ValidatorService } from "services/validators/ValidatorServices";
import { CourseRepository } from "services/firebase/firestore/CourseRepository";
import { FileStorageRepository } from "services/firebase/firestore/FileStorageRepository";
import { FormatterService } from "services/FormatterService";
import { CartService } from "services/CartService";
import { StripeService } from "services/StripeService";

export const contextData = {
  user: null,
  cart: [],
  authenticationService: new AuthenticationService(),
  userRepository: new UserRepository(),
  validatorService: new ValidatorService(),
  courseRepository: new CourseRepository(),
  formatterService: new FormatterService(),
  fileStorageRepository: new FileStorageRepository(),
  cartService: new CartService(),
  stripeService: new StripeService()
}

const contextState = {
  context: contextData,
  setContext: () => {},
}

export const Context = createContext(contextState);