import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IllustrationLayout from "components/layouts/authentication/components/IllustrationLayout";
import chat from "assets/images/illustrations/chat.png";
import heroSignin from "assets/images/hero-signin.png";
import { Context } from "contexts/contextState";
import { errorAlert, successAlert } from "./components/Alerts";

export function LoginView(props) {
  const navigate = useNavigate();
  const { context } = useContext(Context);
  const [rememberMe, setRememberMe] = useState(false);
  const [emailAndPassword, setEmailAndPassword] = useState({ email: "", password: "" });

  function handleLoginAndPassword(event) {
    setEmailAndPassword(previous => ({ ...previous, [event.target.name]: event.target.value }));
  }

  async function handleLoginWithEmailAndPassword(event) {
    if (context.validatorService.isEmail(emailAndPassword.email) && context.validatorService.isStrongPassword(emailAndPassword.password)) {
      try {
        const authResponse = await context.authenticationService.signIn(emailAndPassword.email, emailAndPassword.password);
        const accountResponse = await context.userRepository.findUserById(authResponse.user.uid);
        if (!accountResponse.active) {
          context.authenticationService.signOut();
          return errorAlert("Error!", "Failed to log in. Your account was deleted. Please contact the administrator if you wish to recover it.");
        }
        console.log(handleLoginWithEmailAndPassword.name, authResponse, accountResponse);
        navigate("/courses");
        return successAlert("Success!", "You have been logged in successfully.");
      } catch (error) {
        console.log(handleLoginWithEmailAndPassword.name, error);
      }
    }
    return errorAlert("Error!", "Failed to log in. Please check your email and password and try again.");
  }

  return (
    <IllustrationLayout
      title="Cakes By Sim"
      color="white"
      illustration={{ image: heroSignin }}
      // title="Sign In"
      // description="Enter your email and password to sign in"
      // illustration={{
      //   image: chat,
      //   title: '"Attention is the new currency"',
      //   description:
      //     "The more effortless the writing looks, the more effort the writer actually put into the process.",
      // }}
    >
      <SoftTypography variant="h5" mt={1} mb={1}>Sign In</SoftTypography>
      <SoftTypography variant="body2" mb={2}>Enter your email and password to sign in</SoftTypography>
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" name="email" onChange={handleLoginAndPassword} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large" name="password" onChange={handleLoginAndPassword} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
          <SoftBox textAlign="center">
            <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              onClick={() => setRememberMe(!rememberMe)}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;Remember me
            </SoftTypography>
          </SoftBox>
          <SoftBox textAlign="center">
            <SoftTypography variant="button" color="text" fontWeight="regular">
              <SoftTypography
                component={Link}
                to="/authentication/reset-password"
                variant="button"
                color="info"
                fontWeight="bold"
                textGradient
              >
                Forgot password ?
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton fullWidth variant="gradient" color="info" size="large" onClick={handleLoginWithEmailAndPassword}>
            sign in
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}
