/* eslint-disable react/prop-types */
import { Icon } from "@mui/material";
import SoftButton from "components/SoftButton";

import { useSoftUIController } from "components/layouts/context";
import { useNavigate } from "react-router-dom";


export function NavigateBack({ link }) {
  const navigate = useNavigate();
  const [controller] = useSoftUIController();
  
  const { sidenavColor } = controller;

  return (
    <SoftButton color={sidenavColor} onClick={() => navigate(link)}>
      <Icon sx={{ fontWeight: "bold", mr: 1 }}>arrow_back</Icon>
      Go Back
    </SoftButton>
  )
}