
import { collection, getDocs, getDoc, doc, query, where } from "firebase/firestore";
import { FileStorage, STORAGE_TYPE } from "models/FileStorage";
import { firestore } from "../configs/firebase";
import { environment } from "configs/environment";

export class FileStorageRepository {
    /**
   * Retrieve a file data from firestore collection
   * 
   * @param {string} documentId 
   * @returns {Promise<FileStorage>} 
   */
  async findFileStorageById(documentId) {
    if (documentId) {
      const response = await getDoc(doc(firestore, environment.COLLECTION.FILE_STORAGES, documentId));
      if (response.exists()) {
        const fileParse = new FileStorage({ id: response.id, ...response.data() });
        console.log("findFileById", fileParse);
        return fileParse;
      }
    }
    return null;
  }

  /**
   * Retrieves all image from the Firestore collection.
   * @returns {Promise<Array<FileStorage>>} A Promise that resolves to an array of User objects.
   */
  async findAllImages() {
    const response = await getDocs(
      query(collection(firestore, environment.COLLECTION.FILE_STORAGES), where("type", "==", STORAGE_TYPE.IMAGE))
    )
    const parseResponse = response.docs.map(document => new FileStorage({ id: document.id, ...document.data() }));
    console.log("findAllImages", parseResponse);
    return parseResponse;
  }

  /**
   * Retrieves all video from the Firestore collection.
   * @returns {Promise<Array<FileStorage>>} A Promise that resolves to an array of User objects.
   */
  async findAllVideos() {
    const response = await getDocs(
      query(collection(firestore, environment.COLLECTION.FILE_STORAGES), where("type", "==", STORAGE_TYPE.VIDEO))
    )
    const parseResponse = response.docs.map(document => new FileStorage({ id: document.id, ...document.data() }));
    console.log("findAllVideos", parseResponse);
    return parseResponse;
  }
}
