/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { Card, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useSoftUIController } from "components/layouts/context";
import WavedBlogCardWavesRoot from "components/examples/Cards/BlogCards/WavedBlogCard/WavedBlogCardWavesRoot";
import { Context } from "contexts/contextState";
import defaultImg from 'assets/images/default-image.jpg';


export function CourseCard({ course }) {
  const { context } = useContext(Context);
  const [controller] = useSoftUIController();
  const { sidenavColor } = controller;

  const price = course?.price
    ? context.formatterService.formatCurrency(course?.currency?.value || "cad", course?.price || "")
    : "";

  const description = course?.description
    ? context.formatterService.removeAllHtml(course.description)
    : '';

  return (
    <Card sx={{ height: 500, justifyContent: 'space-between' }}>
      <SoftBox
        component="img"
        src={course?.product?.[1] || defaultImg}
        alt={course?.product?.[0] || 'The main image of the course'}
        sx={{ height: 220, padding: 1, width: '100%', objectFit: 'cover', borderRadius: 5, }}
      />
      <SoftBox position="relative" height="3.125rem" overflow="hidden" zIndex={2} mt={-6.25}>
        <SoftBox position="absolute" width="100%" top={0} zIndex={1}>
          <SoftBox
            component="svg"
            position="relative"
            width="100%"
            height="3.125rem"
            minHeight="3.125rem"
            maxHeight="3.125rem"
            mb={-0.875}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 40"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="card-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <WavedBlogCardWavesRoot>
              <use xlinkHref="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30" />
              <use xlinkHref="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)" />
              <use xlinkHref="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)" />
              <use xlinkHref="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)" />
              <use xlinkHref="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)" />
              <use xlinkHref="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)" />
            </WavedBlogCardWavesRoot>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox ml={1} p={2}>
        <SoftTypography variant="h4">
          {course?.title || ''}
        </SoftTypography>
      </SoftBox>
      <SoftBox ml={2} p={1}>
        <SoftTypography variant="body2" component="p" color="error">
          {price}
        </SoftTypography>
      </SoftBox>
      <SoftBox ml={2} mr={2} p={1}>
        <SoftTypography
          variant="body2"
          component="p"
          color="text"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {description}
        </SoftTypography>
      </SoftBox>
      <SoftBox ml={1} p={2} mb={2}>
        <Link to={`/courses/${course?.id}`} state={{ course }}>
          <SoftButton
            color={sidenavColor}
            sx={{ padding: 2, fontSize: 14, width: '100%' }}
          >
            CHECK THE RECIPE
            <Icon sx={{ fontWeight: "bold", ml: 1 }}>arrow_forward</Icon>
          </SoftButton>
        </Link>
      </SoftBox>
    </Card>
  );
}