import { environment } from "configs/environment";
import { http } from "./http/configs/axios";

export class StripeService {
  async createPayment(amount, email, metadata) {
    const price = Number(String(amount).replace(".", ""));
    const headers = { "Authorization": `Bearer ${localStorage.getItem("firebaseToken")}` };
    const response = await http.post(environment.API.ENDPOINTS.CREATE_PAYMENT, {
      amount: price,
      currency: "CAD",
      email: email,
      // metadata: {
      //   data: metadata || null,
      // }
    }, { headers });
    return response.data;
  }
}