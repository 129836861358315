/* eslint-disable react/prop-types */
import React from "react";

import SoftBox from "components/SoftBox";
import { WhatsApp } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";

export function WhatsAppWidget() {
  const isMobileView = useMediaQuery('(max-width:600px)');

  //WIP - move to more safe place
  const link = "https://api.whatsapp.com/send?phone=16473385533&text=Hello%2C%20I%20would%20like%20more%20information%20about%20your%20cakes!%0A";

  return (
    <SoftBox sx={{ 
      position: 'sticky',
      bottom: 20,
      zIndex: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      }}
    >
      <SoftBox  sx={{
          height: isMobileView ? 50 : 75,
          width: isMobileView ? 50 : 75,
          borderRadius: 25,
          backgroundColor: '#4ad876',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <SoftBox
            component="a"
            href={link}
            target="_blank"
            rel="noreferrer"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center', 
            }}
          >
          <WhatsApp sx={{ width: isMobileView ? 35 : 50, height: isMobileView ? 35 : 50, color: '#fff' }} />
        </SoftBox>
      </SoftBox>
    </SoftBox>
  )
}