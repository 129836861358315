import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { useContext } from "react";
import defaultImage from "assets/images/default-image.jpg";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export function CardItem({ course, handleDelete }) {
  const isMobileView = useMediaQuery('(max-width:600px)');
  const { context } = useContext(Context);

  const price = context.formatterService.formatCurrency("cad", course.price);
  const description = context.formatterService.removeAllHtml(course.description);

  return (
    <SoftBox p={3} sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: isMobileView ? "column" : "row",
    }}>
      <SoftBox flex='1' mb={isMobileView ? 3 : 0}>
        <Link to={`/courses/${course.id}`}>
          <SoftBox
            component="img"
            src={course.image || defaultImage}
            alt={`Image of the ${course.title}`}
            sx={{ height: 120, padding: 1, width: '100%', objectFit: 'contain', borderRadius: 5, }}
          />
        </Link>
      </SoftBox>
      <SoftBox flex='2' mb={isMobileView ? 5 : 0}>
        <Link to={`/courses/${course.id}`}>
          <SoftTypography
            component="p"
            sx={{ 
              mb: isMobileView ? 3 : 0,
              textAlign: isMobileView ? 'center' : 'start',
            }}
          >
            {course.title}
          </SoftTypography>
          <SoftTypography
            component="p"
            sx={{ 
              textAlign: isMobileView ? 'justify' : 'start',
            }}
          >
            {description}
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox flex='1' textAlign='center' mb={isMobileView ? 3 : 0}>
        <SoftTypography component="h5">Price: {price}</SoftTypography>
      </SoftBox>
      <SoftBox flex='1' textAlign='center'>
        <SoftButton
          varian="text"
          color="warning"
          size={isMobileView ? "large" : "small"}
          onClick={() => handleDelete(course.id)}
        >
          Delete
        </SoftButton>
      </SoftBox>
    </SoftBox>
  )
}

CardItem.propTypes = {
  course: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired
};