/* eslint-disable react/prop-types */
import SoftBox from "components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

export function DeleteAccount(props) {
  return (
    <Card id="delete-account">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Delete Account</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Once you delete your account, there is no going back. Please be certain.
        </SoftTypography>
      </SoftBox>
      <SoftBox
        pb={3}
        px={3}
        display="flex"
        justifyContent="flex-end"
        alignItems={{ xs: "flex-end", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <SoftButton onClick={props?.handleModal} variant="gradient" color="error" sx={{ height: "100%" }}>
              delete account
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}