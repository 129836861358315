import PropTypes from "prop-types";
import { PaymentElement, LinkAuthenticationElement } from '@stripe/react-stripe-js'
import { useContext, useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import { errorAlert } from 'views/components/Alerts';
import { Context } from "contexts/contextState";

// eslint-disable-next-line react/prop-types
export function CheckoutForm(props) {
  const {context} = useContext(Context);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    if (stripe && elements) {
      setIsLoading(true);
      try {
        const stripePaymentResponse = await stripe.confirmPayment({ elements, redirect: "if_required" });
        if (stripePaymentResponse.error) {
          if (["card_error", "validation_error"].includes(stripePaymentResponse.error.type)) {
            errorAlert("Error", stripePaymentResponse.error.message);
          } else {
            errorAlert("Error", "An unexpected error occurred.");
          }
        } else {
          return props.onSuccess();
        }
      } catch (error) {
        console.log('Error: ', error)
      }

      setIsLoading(false);
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element" />
      <PaymentElement id="payment-element" options={{
        defaultValues: {
          billingDetails: {
            email: context.user.email || "",
          }
        }
      }} />
      <SoftBox display="flex" alignItems="center" justifyContent="center" flex={1} mt={2}>
        <SoftButton color='success' disabled={isLoading || !stripe || !elements} onClick={handleSubmit}>
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </SoftButton>
      </SoftBox>
    </form>
  )
}

CheckoutForm.propTypes = {
  onSuccess: PropTypes.func.isRequired
};