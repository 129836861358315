import YoutubePlayer from "react-player";
import VimeoPlayer from "react-player/vimeo";
import PropTypes from "prop-types";
import { Fragment } from "react";

export function VideoPlayer(props) {
  return (
    <Fragment>
      {props.videoUrl?.includes("vimeo") && <VimeoPlayer url={props.videoUrl} width={props?.width ? props?.width : "540px"} controls />}
      {!props.videoUrl?.includes("vimeo") && <YoutubePlayer url={props.videoUrl} width={props?.width ? props?.width : "540px"} controls />}
    </Fragment>
  );
}

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
}