export class Video {
  constructor(entity) {
    this.id = entity?.id || Date.now();
    this.title = entity?.title || null;
    this.active = entity?.active || false;
    this.privacy = entity?.privacy || VIDEO_ENUM.PUBLIC;
    this.description = entity?.description || null;
    this.youtubeLink = entity?.youtubeLink || null;
    this.videoReference = entity?.videoReference || null;
    this.updatedDate = entity?.updatedDate || null;
    this.createdDate = entity?.createdDate || null;
  }
}

export const VIDEO_ENUM = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE"
}

export const VIDEO_STATUS = [
  {
    label: "Active",
    value: true
  },
  {
    label: "Disabled",
    value: false
  },
]

export const VIDEO_PRIVACY = [
  {
    label: "Public",
    value: VIDEO_ENUM.PUBLIC
  },
  {
    label: "Private",
    value: VIDEO_ENUM.PRIVATE
  },
]