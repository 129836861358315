import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { SoftUIControllerProvider } from "components/layouts/context";
import { Root } from "views/Root";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Root>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </Root>
  </BrowserRouter>
);
