import { collection, getDocs, where, documentId, query, serverTimestamp, doc, updateDoc } from "firebase/firestore";
import { User } from "models/User";
import { firestore } from "../configs/firebase";
import { environment } from "configs/environment";

/**
 * Represents a repository for managing user data in a Firestore database.
 */
export class UserRepository {
  /**
   * Retrieves a user by their ID from the Firestore collection.
   * 
   * @param {string} accountID The ID of the user to retrieve.
   * @returns {Promise<User|null>} A Promise that resolves to a User object if a user with the specified ID is found, or null if not.
   */
  async findUserById(accountID) {
    const referenceCollection = collection(firestore, environment.COLLECTION.ACCOUNTS);
    const response = await getDocs(query(referenceCollection, where(documentId(), "==", accountID)));
    const parseResponse = response.docs.map(document => new User({ id: document.id, ...document.data() }));
    const result = !!parseResponse.length ? parseResponse[0] : null;
    console.log("findUserById", result);
    return result;
  }

    /**
   * Saves a user to the Firestore collection.
   * If the user object has an ID, the corresponding document will be updated with the new data.
   * Otherwise, a new document will be created.
   * @param {User} user The user object to save.
   * @returns {Promise<User>} A Promise that resolves to the updated or newly created User object.
   */
  async saveUser(user) {
    const referenceCollection = collection(firestore, environment.COLLECTION.ACCOUNTS);
    const account = { ...user }
    if (account.id) {
      const accountId = account.id;
      delete account.id;
      account.updatedDate = serverTimestamp();
      const documentReference = doc(firestore, environment.COLLECTION.ACCOUNTS, accountId)
      await updateDoc(documentReference, account)
      return new User({ id: accountId, ...account, updatedDate: new Date() });
    } else {
      account.createdDate = serverTimestamp();
      const response = await addDoc(referenceCollection, account)
      return new User({ ...user, id: response.id, createdDate: new Date() })
    }
  }
}
