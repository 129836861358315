/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, Icon, useMediaQuery, CircularProgress } from "@mui/material";
import { useParams } from 'react-router-dom'
import { Context } from "contexts/contextState";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import { VideoPreviewModal } from '../components/VideoPreviewModal'
import { VideoPlayer } from "views/components/VideoPlayer";
import BaseLayout from 'components/layouts/pages/account/components/BaseLayout';

import defaultImg from 'assets/images/default-image.jpg'
import { NavigateBack } from '../components/NavigateBack';

export function PurchasedCourse(props) {
  /****************** hooks ********************/
  const params = useParams();
  const { context } = useContext(Context);
  const isMobileView = useMediaQuery('(max-width:600px)');

  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [courseData, setCourseData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  /****************** functions ********************/
  const handleModal = () => setIsModalOpen(previous => !previous);

  async function handlePageLoader() {
    try {
      const courseResponse = await context.courseRepository.findCourseById(params.id)
  
      if (courseResponse) {
        setCourseData(courseResponse)
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setFirstPageLoader(false);
    }
  }

  /****************** useEffects ********************/
  useEffect(() => {
    if (firstPageLoader) {
      handlePageLoader();
    }
  }, []);

  return ( 
    <BaseLayout stickyNavbar>
      <SoftBox mt={-3} ml={2} mb={3}>
        <NavigateBack link={'/purchases'} />
      </SoftBox>
      <SoftBox mb={2}>
      {firstPageLoader ? (
        <Card>
          <SoftBox
            display={'flex'}
            height={500}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <CircularProgress color={"info"} />
          </SoftBox>
        </Card>
      ) : (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={9} lg={9}>
            <Card
              sx={{ margin: isMobileView ? 0 : 2, mt: 0, mb: isMobileView ? 2 : 0 }}
            >
              <SoftBox p={3}>
                <SoftBox p={3}>
                  <SoftTypography variant="h5" gutterBottom>
                    {courseData?.title}
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={3}>
                  {context.formatterService.formatStringAsHtml(courseData?.description)}
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Card sx={{ padding: 2 }}>
              <SoftBox sx={{ borderRadius: 2 }} mb={2}>
                {courseData?.youtubeLink ? (
                  <VideoPlayer videoUrl={courseData?.youtubeLink} width="100%" />
                ) : (
                  <SoftBox
                    component="img"
                    src={defaultImg}
                    alt={"The main image of the course"}
                    sx={{
                      height: 220,
                      padding: 1,
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                  />
                )}
              </SoftBox>
              <SoftButton
                color={"info"}
                sx={{ padding: 2, fontSize: 14, marginTop: 4, marginBottom: 4 }}
                onClick={handleModal}
              >
                Browse the Video
                <Icon sx={{ fontWeight: "bold", ml: 1 }}>arrow_forward</Icon>
              </SoftButton>
            </Card>
          </Grid>
        </Grid>
      )}
      </SoftBox>
      {isModalOpen && <VideoPreviewModal open={isModalOpen} onClose={handleModal} video={courseData?.videos?.[0]} />}
    </BaseLayout>
  )
}
