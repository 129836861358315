import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import SoftButton from 'components/SoftButton';
import { environment } from 'configs/environment';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { Context } from "contexts/contextState";


export function StripePaymentButton(props) {
  const { context } = useContext(Context);
  const [stripe, setStripe] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  async function handlePaymentConfirm() {
    props.onClick();
    try {
      const stripeResponse = await context.stripeService.createPayment(
        props.amount,
        context?.user?.email || "",
        { cart: context.cart,
        }
      );
      setClientSecret(stripeResponse.client_secret);
    } catch (error) {
      console.log("handlePaymentConfirm", error);
      props.onError("An error occurred while processing the payment.");
    }
  };

  useEffect(() => {
    loadStripe(environment.STRIPE.PUBLISHABLE_KEY).then(response => setStripe(response));
  }, []);

  return (
    <>
      {!clientSecret && <SoftButton color="success" onClick={handlePaymentConfirm}>Confirm</SoftButton>}
      {clientSecret && stripe && (
        <Elements stripe={stripe} options={{ clientSecret }}>
          <CheckoutForm onSuccess={props.onSuccess} />
        </Elements>
      )}
    </>
  );
};

StripePaymentButton.propTypes = {
  amount: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
