/* eslint-disable react/prop-types */
import { Card, CircularProgress, Divider, useMediaQuery } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Context } from "contexts/contextState";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import BaseLayout from 'components/layouts/pages/account/components/BaseLayout';
import { successAlert } from "../components/Alerts";
import { NavigateBack } from "../components/NavigateBack";
import { ConfirmPurchaseModal } from "./components/ConfirmPurchaseModal";
import { CardItem } from "./fragments/CardItem";
import { ConfirmDeleteModal } from "./components/ConfirmDeleteModal";

const INIT_MODALS = { delete: false, purchase: false };

export function ShoppingCart(props) {
  const { context, setContext } = useContext(Context);
  const isMobileView = useMediaQuery('(max-width:600px)');
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(INIT_MODALS);
  const deletableCourse = useRef(null);

  const priceConvertor = (price) => context.formatterService.formatCurrency('cad', price);

  function handleCloseModal() {
    setOpenModal(INIT_MODALS);
  }

  function handleOpenModal(type) {
    setOpenModal(prev => ({
      ...prev,
      [type]: true,
    }));
  }

  async function fetchCourses() {
    try {
      const allCourses = context.cartService.findAllCoursesFromCart(context.user?.uid);
      if (allCourses?.length) {
        const courseResponse = await Promise.all(allCourses.map(course => context.courseRepository.findCourseById(course.courseId)));
        const allImages = await context.fileStorageRepository.findAllImages();
        if (courseResponse?.length) {
          setCourses(courseResponse.map(course => ({
            ...course,
            image: allImages?.find(img => img?.id === course?.thumbnailReference?.id)?.fileUrl || null
          })));
        }
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const courseValue = useMemo(() => {
    if (courses?.length) {
      const subTotal = courses.reduce((total, actual) => total + Number(actual.price), 0);
      const taxes = Number((subTotal / 100 * 13).toFixed(2));
      const total = Number((subTotal + taxes).toFixed(2));
      return { subTotal, taxes, total }
    } else {
      return { subTotal: 0, taxes: 0, total: 0 }
    }
  }, [courses])

  async function handleConfirmPurchase() {
    const account = await context.userRepository.findUserById(context.user.uid);
    account.purchases = [...account.purchases, ...courses.map(course => course.id)];
    await context.userRepository.saveUser(account);
    courses.forEach((course) => context.cartService.removeCourseFromCart(context?.user?.uid, course?.id));
    setContext({ cart: [] });
    setCourses([]);
    handleCloseModal();
    successAlert('Success', `You made a purchase for a total amount of ${priceConvertor(courseValue.total)}`)
  }

  function handleConfirmDelete() {
    const deleteCourseId = deletableCourse.current;
    if (deleteCourseId) {
      const course = courses?.find(course => course?.id === deleteCourseId);
      setCourses(prev => prev.filter(course => course.id !== deleteCourseId));
      context.cartService.removeCourseFromCart(context?.user?.uid, deleteCourseId);
      setContext({ cart: context.cart.filter(course => course?.id === deleteCourseId) });
      handleCloseModal();
      return successAlert('Success!', `The course "${course?.title}" was successfully deleted from your shopping cart!`);
    }
  }

  const handleDelete = (id) => {
    if (id) {
      deletableCourse.current = id;
      handleOpenModal('delete');
    }
  }

  const handleCheckout = () => {
    if (courses.length) {
      handleOpenModal('purchase');
    }
  }

  useEffect(() => {
    fetchCourses();
  }, [context.cartService.findAllCoursesFromCart(context.user?.uid)?.length])

  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={-3} mb={3}>
        <NavigateBack link={-1} />
      </SoftBox>
      <Card sx={{ marginBottom: 3 }}>
        <SoftBox p={3}>
          <SoftTypography fontWeight="bold" variant={'h3'}>Shopping Cart</SoftTypography>
        </SoftBox>
        {loading ? (
          <Card>
            <SoftBox
              display={'flex'}
              height={500}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <CircularProgress color={"info"} />
            </SoftBox>
          </Card>
        ) : (
          courses?.length ? (
            <>
              <SoftBox p={3} pb={1}>
                <SoftTypography variant={'h5'}>{`${courses.length} Courses in Cart`}</SoftTypography>
              </SoftBox>
              <SoftBox p={3} pt={0} display="flex">
                <SoftBox flex="3">
                  {courses.map(course => {
                    return (
                      <SoftBox key={course.id}>
                        <Divider />
                        <CardItem handleDelete={handleDelete} course={course} />
                      </SoftBox>
                    )
                  })}
                </SoftBox>
              </SoftBox>
              <Divider />
              <SoftBox 
                display={'flex'}
                justifyContent={'flex-end'}
                m={3}
              >
                <SoftBox>
                  <SoftTypography variant={isMobileView ? "h6" : "h5"} p={3} pb={0}>SUB-TOTAL:</SoftTypography>
                  <SoftTypography variant={isMobileView ? "h6" : "h5"} p={3} pb={0}>HST/GST:</SoftTypography>
                  <SoftTypography variant={isMobileView ? "h6" : "h5"} p={3} pb={0}>Total:</SoftTypography>
                </SoftBox>
                <SoftBox>
                  <SoftTypography variant="h5" p={3} pb={0}>{priceConvertor(courseValue.subTotal)}</SoftTypography>
                  <SoftTypography variant="h5" p={3} pb={0}>{priceConvertor(courseValue.taxes)}</SoftTypography>
                  <SoftTypography variant="h5" p={3} pb={0}>{priceConvertor(courseValue.total)}</SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox display={"flex"} justifyContent={isMobileView ? "center" : "flex-end"}>
                <SoftBox m={3} mt={0} p={3}>
                  <SoftButton
                    color={"info"}
                    size="large"
                    onClick={handleCheckout}
                  >
                    Checkout
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </>
          ) : (
            <SoftBox p={6} display="flex" alignItems="center" justifyContent="center">
              <SoftTypography>Your Cart is Empty</SoftTypography>
            </SoftBox>
          )
        )}
      </Card>
      {openModal.purchase ? (
        <ConfirmPurchaseModal
          open={openModal.purchase}
          onClose={handleCloseModal}
          onConfirm={handleConfirmPurchase}
          quantity={courses.length}
          displayingPrice={priceConvertor(courseValue.total)}
          price={courseValue.total}
        />
      ) : false}
      {openModal.delete ? (
        <ConfirmDeleteModal
          open={openModal.delete}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          course={courses?.find(course => course.id === deletableCourse.current)}
        />
      ) : false}
    </BaseLayout>
  )
}
