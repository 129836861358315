/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import { useContext } from "react";
import { Context } from "contexts/contextState";
import Card from "@mui/material/Card";
import SoftButton from "components/SoftButton";
import FormField from "components/layouts/pages/account/components/FormField";
import { User } from "models/User";
import { successAlert } from "views/components/Alerts";

export function BasicInfo(props) {
  const { context } = useContext(Context);

  async function handleUpdate() {
    const account = new User(props.user);
    await context.userRepository.saveUser(account);
    successAlert("Success", "User Details Updated Successfully");
  }

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <SoftBox component="form" p={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              onChange={(e) => { props.user.firstName = e.target.value }} label="first name" placeholder={props.user?.firstName || ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              onChange={(e) => { props.user.lastName = e.target.value }} label="last name" placeholder={props.user?.lastName || ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              readOnly
              onChange={(e) => { props.user.email = e.target.value }}

              label="email"
              placeholder={props.user?.email || ''}
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              onChange={(e) => { props.user.phone = e.target.value }}
              label="phone number"
              placeholder={props.user?.phone || ''}
              inputProps={{ type: "number" }}
            />
          </Grid>
        </Grid>
        <SoftBox ml={{ xs: 0, sm: 0 }} mt={{ xs: 3, sm: 4 }} display="flex" justifyContent="flex-end">
          <SoftButton onClick={handleUpdate} variant="gradient" color="success" sx={{ height: "100%" }}>
            update account
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}