/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React icons
import Document from "components/examples/Icons/Document";
import { LoginView } from "views/LoginView";
import Default from "components/layouts/dashboards/default";
import { Person, Shop, ShopTwo, Dashboard } from "@mui/icons-material";
import { SignUpView } from "views/SignUpView";
import { ResetPasswordView } from "views/ResetPasswordView";
import Settings from "components/layouts/pages/account/settings";
import { AllCourses } from "views/courses/AllCourses";
import { Course } from "views/courses/Course";
import { PurchasedCourses } from "views/purchases/PurchasedCourses";
import { PurchasedCourse } from "views/purchases/PurchasedCourse";
import { Profile } from 'views/profile/Profile';
import { ShoppingCart } from "views/cart/ShoppingCart";

const routes = [
  {
    type: "collapse",
    name: "Offered Courses",
    key: "courses",
    icon: <ShopTwo size="12px" />,
    collapse: [
      {
        name: "Courses",
        key: "courses",
        route: "/courses",
        component: <AllCourses />,
      },
      {
        name: "Course",
        key: "course",
        route: "/courses/:id",
        component: <Course />,
        hidden: true
      },
    ],
  },
  {
    type: "collapse",
    name: "Purchased Courses",
    key: "purchases",
    icon: <Shop size="12px" />,
    collapse: [
      {
        name: "Purchased Courses",
        key: "purchases",
        route: "/purchases",
        component: <PurchasedCourses />,
      },
      {
        name: "Purchased Course",
        key: "purchases",
        route: "/purchases/:id",
        component: <PurchasedCourse />,
        hidden: true
      },
    ],
  },
  {
    type: "collapse",
    name: "My Profile",
    key: "profile",
    icon: <Person size="12px" />,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        route: "/profile",
        component: <Profile />,
      },
    ],
  },
  {
    name: "Shopping Cart",
    key: "cart",
    collapse: [
      {
        name: "Shopping Card",
        key: "cart",
        route: "/cart",
        component: <ShoppingCart />,
      },
    ],
  },
  {
    // type: "collapse",
    name: "Authentication",
    key: "authentication",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Sign In",
            key: "sign-in-illustration",
            route: "/authentication/sign-in",
            component: <LoginView />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Sign Up",
            key: "sign-up-illustration",
            route: "/authentication/sign-up",
            component: <SignUpView />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/reset-password",
            component: <ResetPasswordView />,
          },
        ],
      }
    ],
  },
];

export default routes;
