/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import { Grid, Card, CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import BaseLayout from 'components/layouts/pages/account/components/BaseLayout';
import { Context } from 'contexts/contextState';
import { CustomHeader } from 'views/components/CustomHeader';
import { CourseCard } from './components/CourseCard';

export function PurchasedCourses() {
  /****************** hooks ********************/
  const { context } = useContext(Context);

  const [firstPageLoader, setFirstPageLoader] = useState(true);
  const [courses, setCourses] = useState([]);

  /****************** functions ********************/
  async function handlePageLoader() {
    try {
      const userData = await context.userRepository.findUserById(context.user.uid);
      const userPurchases = userData.purchases.map(purchaseID => context.courseRepository.findCourseById(purchaseID));

      const courseResponse = await Promise.all(userPurchases);
      const imagesResponse = await context.fileStorageRepository.findAllImages();
  
      if (courseResponse && imagesResponse) {
        setCourses(courseResponse.map(course => ({
          ...course,
          product: [course.title, imagesResponse.find(image => image.id == course.thumbnailReference?.id)?.fileUrl ],
          level: course.courseLevel.label,
          price: course.price,
          active: course.active,
        })))
      }
    } catch (error) {
      console.log('Error: ', error)
    } finally {
      setFirstPageLoader(false);
    }
  }

  /****************** useEffects ********************/
  useEffect(() => {
    if (firstPageLoader) {
      handlePageLoader();
    }
  }, []);

  return (
    <BaseLayout stickyNavbar>
      <CustomHeader title="Some of Your Purchased Courses about Awesome Cakes Recipes" description="Take the opportunity to bake the cake of your dreams - yourself!" />
      <SoftBox pt={5} pb={2}>
        <SoftBox mt={{ xs: 1, lg: 3 }} mb={1}>
        {firstPageLoader ? (
          <Card>
            <SoftBox
              display={'flex'}
              height={500}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <CircularProgress color={"info"} />
            </SoftBox>
          </Card>
        ) : (
          courses.length ? (
            <Grid container spacing={3}>
              {courses.map((course, index) => {
                return (
                  <Grid item xs={12} md={4} lg={3} key={course?.id}>
                    <CourseCard course={course} />
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <SoftBox sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <SoftTypography mb={2} variant='h4'>You have not purchased any courses yet!</SoftTypography>
              <SoftTypography mb={2} variant='h4'>It is a great time to do it!</SoftTypography>
              <SoftButton component={Link} to={'/courses'} color={"info"} mb={2}>check courses</SoftButton>
            </SoftBox>
          ))}
        </SoftBox>
      </SoftBox>
    </BaseLayout>
  );
}
