import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

export function ConfirmDeleteModal(props){
  return (
    <Dialog open={props?.open} onClose={props?.onClose}>
      <DialogTitle>
        <SoftBox mx="16px" mt="16px">
          <SoftTypography variant="h3">Confirming deleting action</SoftTypography>
        </SoftBox>
      </DialogTitle>
      <DialogContent>
        <SoftBox mx="16px" mt="16px">
          <SoftTypography variant="h5">{`Are you sure you wish to delete a course "${props?.course?.title}" from the cart?`}</SoftTypography>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <SoftBox display="flex" justifyContent="space-between" flex="1">
          <SoftButton color="light" onClick={props?.onConfirm}>Confirm</SoftButton>
          <SoftButton color="light" onClick={props?.onClose}>Cancel</SoftButton>
        </SoftBox>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  course: PropTypes.object.isRequired,
};