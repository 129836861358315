import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { authentication } from "services/firebase/configs/firebase";

/**
 * Provides authentication-related methods for interacting with Firebase Authentication.
 */
export class AuthenticationService {

  /**
   * Creates a new user account with the given email and password.
   * 
   * @param {string} email - The email address to use for the new account.
   * @param {string} password - The password to use for the new account.
   * @returns {Promise<object>} A Promise that resolves with the user information when the account is created successfully.
   */
  async createAccount(email, password) {
    const response = await createUserWithEmailAndPassword(authentication, email, password);
    console.log("createAccount", email, password, response);
    return response;
  }

  /**
   * Signs in the user with the given email and password.
   * 
   * @param {string} email - The email address of the user.
   * @param {string} password - The password of the user.
   * @returns {Promise<object>} A Promise that resolves with the user information when the user is signed in successfully.
   */
  async signIn(email, password) {
    const response = await signInWithEmailAndPassword(authentication, email, password);
    console.log("signIn", email, password, response);
    return response;
  }

  /**
   * Signs out the current user.
   * 
   * @returns {Promise<void>} A Promise that resolves when the user is signed out successfully.
   */
  async signOut() {
    const response = await signOut(authentication);
    console.log("signOut", response);
    return response;
  }

  /**
   * Sends a password reset email to the given email address.
   * 
   * @param {string} email - The email address of the user who forgot their password.
   * @returns {Promise<void>} A Promise that resolves when the password reset email is sent successfully.
   */
  async resetAccountPassword(email) {
    const response = await sendPasswordResetEmail(authentication, email);
    console.log("resetAccountPassword", response);
    return response;
  }

  async updateAccountPassword(password) {
    const response = await updatePassword(authentication.currentUser, password);
    console.log("updateUserPassword", response);
    return response;
  }
}
