/**
 * Object containing environment variables for the application.
 * @typedef {Object} Env
 * @property {Object} FIREBASE - Object containing Firebase configuration variables.
 * @property {string} FIREBASE.apiKey - Firebase API key.
 * @property {string} FIREBASE.authDomain - Firebase auth domain.
 * @property {string} FIREBASE.projectId - Firebase project ID.
 * @property {string} FIREBASE.storageBucket - Firebase storage bucket.
 * @property {string} FIREBASE.messagingSenderId - Firebase messaging sender ID.
 * @property {string} FIREBASE.appId - Firebase app ID.
 * @property {string} FIREBASE.measurementId - Firebase measurement ID.
 * @property {Object} STRIPE - Object containing Stripe configuration variables.
 * @property {string} STRIPE.PUBLISHABLE_KEY - Stripe Pub key.
 * @property {Object} COLLECTION - Object containing Firebase collection variables.
 * @property {string} COLLECTION.ANALYSIS - Firebase collection
 * @property {string} COLLECTION.ACCOUNTS - Firebase collection
 * @property {string} COLLECTION.FILE_STORAGES - Firebase collection
 * @property {string} COLLECTION.COURSES - Firebase collection
 * @property {Object} API - Object containing API host and endpoints variables.
 * @property {string} API.HOST - Firebase collection
 * @property {Object} API.ENDPOINTS - Firebase collection
 * @property {string} API.ENDPOINTS.VIDEO_UPLOAD - Firebase collection
 * @property {string} API.ENDPOINTS.IMAGE_UPLOAD - Firebase collection
 * @property {string} API.ENDPOINTS.CREATE_PAYMENT - Firebase collection
 */

const env = {
  production: {
    FIREBASE: {
      apiKey: "AIzaSyCIuKQCG9pEpO0bB_5CRMiiqQH3v-6k8Jc",
      authDomain: "cakesbysim-prod.firebaseapp.com",
      projectId: "cakesbysim-prod",
      storageBucket: "cakesbysim-prod.appspot.com",
      messagingSenderId: "299023688407",
      appId: "1:299023688407:web:2a8985d4fe2be7b7cd0692",
      measurementId: "G-FTZ2NYY1Y0"
    },
    STRIPE: {
      PUBLISHABLE_KEY: "pk_live_51NQb68Bq6K1p7ve7dumt14YB9H3Z1puYYIkcfJezrkYxPwT1WVjDyAQasax8UbHUm49krW3TlrKlcQVjhTlCtMuT0069GLLrRp",
    },
    COLLECTION: {
      ANALYSIS: "analysis",
      ACCOUNTS: "users",
      FILE_STORAGES: "file-storages",
      COURSES: "courses"
    },
    API: {
      HOST: "https://us-central1-cakesbysim-dev-354c6.cloudfunctions.net/api",
      ENDPOINTS: {
        VIDEO_UPLOAD: "/upload/video",
        IMAGE_UPLOAD: "/upload/image",
        CREATE_PAYMENT: "/payments/create"
      }
    }
  },
  development: {
    FIREBASE: {
      apiKey: "AIzaSyAU3r6V7xHRxwgoYNNv87ilJwEYoLUrcZU",
      authDomain: "cakesbysim-dev-354c6.firebaseapp.com",
      projectId: "cakesbysim-dev-354c6",
      storageBucket: "cakesbysim-dev-354c6.appspot.com",
      messagingSenderId: "745278156353",
      appId: "1:745278156353:web:c340c34849fa866a3ea0c0"
    },
    STRIPE: {
      PUBLISHABLE_KEY: "pk_test_51MON7QH5mry17ryFMHQdrdPoJjQv5zpOKDqgs2RC79CzTmZlk7uWtthBtIW09svmg2ZRzQK2y11lB1nFt03c2Caw007bIa5Wml",
    },
    COLLECTION: {
      ANALYSIS: "analysis",
      ACCOUNTS: "users",
      FILE_STORAGES: "file-storages",
      COURSES: "courses"
    },
    API: {
      HOST: "https://us-central1-cakesbysim-dev-354c6.cloudfunctions.net/api",
      ENDPOINTS: {
        VIDEO_UPLOAD: "/upload/video",
        IMAGE_UPLOAD: "/upload/image",
        CREATE_PAYMENT: "/payments/create"
      }
    }
  }
};

const environmentResolver = (process.env.REACT_APP_ENV || "development");
console.log("Environment(Web): %s", environmentResolver);

/**
 * Object containing the current environment's configuration variables.
 * @type {Env}
 */
export const environment = env[environmentResolver];