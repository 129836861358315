import { collection, getDocs, getDoc, doc, where, query } from "firebase/firestore";
import { firestore } from "../configs/firebase";
import { Course } from "models/Course";
import { environment } from "configs/environment";

export class CourseRepository {
  /**
   * Retrieves all users from the Firestore collection.
   * @returns {Promise<Array<Course>>} A Promise that resolves to an array of Course objects.
   */
  async findAllCourse() {
    const videos = query(collection(firestore, environment.COLLECTION.COURSES), where('active', '==', true));
    const response = await getDocs(videos);
    const parseResponse = response.docs.map(document => new Course({ id: document.id, ...document.data() }));
    console.log("findAllCourse", parseResponse);
    return parseResponse;
  }

  /**
 * Retrieve a course from the Firestore collection.
 * @param {string} courseId - document id from firebase
 * @returns {Promise<Course>} A Promise that resolves to a Course objects.
 */
  async findCourseById(courseId) {
    console.log(courseId)
    const document = await getDoc(doc(firestore, environment.COLLECTION.COURSES, courseId));
    console.log(document)
    if (document.exists()) {
      const parseCourse = new Course({ id: document.id, ...document.data() });
      console.log("findCourseById", parseCourse);
      return parseCourse;
    }
    return null;
  }
}