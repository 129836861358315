/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import BaseLayout from "components/layouts/pages/account/components/BaseLayout";
import { useContext, useEffect, useState } from "react";
import { Context } from "contexts/contextState";
import { useMediaQuery, Modal } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import defaultAvatar from 'assets/images/default-avatar.png'
import { NavigateBack } from "../components/NavigateBack";
import { BasicInfo } from "./fragments/BasicInfo";
import { DeleteAccount } from "./fragments/DeleteAccount";
import { User } from "models/User";
import { errorAlert, successAlert } from "views/components/Alerts";
import { ChangePassword } from "views/components/ChangePassword";
import { useNavigate } from "react-router-dom";

export function Profile() {
  /****************** hooks ********************/
  const navigate = useNavigate("/courses")
  const { context } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const isMobileView = useMediaQuery('(max-width:600px)');

  /****************** functions ********************/
  const handleModal = () => setIsModalOpen(previous => !previous);

  async function handlePageLoader(id) {
    try {
      const userResponse = await context.userRepository.findUserById(id)
      if (userResponse) {
        setUser(userResponse);
      }
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  async function handleDeleteConfirm() {
    try {
      const account = new User(user);
      account.active = false;
      await context.userRepository.saveUser(account);
      context.authenticationService.signOut().then(handleModal);
      navigate("/courses");
      successAlert("Success", "Used Deleted Successfully");
    } catch (error) {
      errorAlert("Error", error.message);
    }
  }

  /****************** useEffects ********************/

  useEffect(() => {
    if (context?.user?.uid) {
      handlePageLoader(context?.user?.uid);
    }
  }, [context?.user?.uid]);

  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={-3} ml={2} mb={3}>
        <NavigateBack link={'/courses'} />
      </SoftBox>
      <SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <SoftBox p={3}>
                <SoftTypography variant="h5">Profile Details</SoftTypography>
              </SoftBox>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SoftAvatar
                    src={user?.imageProfileReference || defaultAvatar}
                    alt="profile-image"
                    variant="rounded"
                    size="xxl"
                    shadow="sm"
                    sx={{ m: 3 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo user={user} />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                <Grid item xs={12}>
                  <DeleteAccount handleModal={handleModal} />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      {isModalOpen ? (
        <Modal open={isModalOpen} onClose={handleModal}>
          <SoftBox sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 2,
            width: isMobileView ? 310 : 450,
            height: isMobileView ? 310 : 200,
            backgroundColor: '#fff',
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 5,
            flexDirection: 'column'
          }}>
            <SoftTypography component={'p'} textAlign={'justify'}>Are you sure you want to delete your account?</SoftTypography>
            <SoftBox sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 5,
            }}>
              <SoftButton m={2} variant="outlined" color="success" onClick={handleDeleteConfirm}>
                confirm
              </SoftButton>
              <SoftButton ml={2} variant="outlined" color="secondary" onClick={handleModal}>
                cancel
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Modal>
      ) : false}
    </BaseLayout>
  );
}
