import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import IllustrationLayout from "components/layouts/authentication/components/IllustrationLayout";
import lock from "assets/images/illustrations/lock.png";
import heroResetPass from "assets/images/hero-resetpass.png";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { Context } from "contexts/contextState";
import { errorAlert, successAlert } from "./components/Alerts";


export function ResetPasswordView() {
  const [emailAccount, setEmailAccount] = useState("");
  const { context } = useContext(Context);

  async function handleResetAccountPassword() {
    if (context.validatorService.isEmail(emailAccount)) {
      try {
        const response = await context.authenticationService.resetAccountPassword(emailAccount);
        console.log(handleResetAccountPassword.name, emailAccount, response);
        return successAlert("Success!", "An email has been sent to your email address. Please check your spam folder if you don't see it in your inbox.");
      } catch (error) {
        console.log(handleResetAccountPassword.name, error);
      }
    }
    return errorAlert("Error!", "Failed to send email. Please check that your email address is correct and try again.");
  }

  return (
    <IllustrationLayout
      title="Cakes By Sim"
      color="white"
      illustration={{ image: heroResetPass }}
      // title="Reset Password"
      // description="You will receive an e-mail in maximum 60 seconds"
      // illustration={{
      //   image: lock,
      //   title: "Soft UI Design",
      //   description:
      //     "Just as it takes a company to sustain a product, it takes a community to sustain a protocol.",
      // }}
    >
      <SoftTypography variant="h5" mt={1} mb={1}>Reset Password</SoftTypography>
      <SoftTypography variant="body2" mb={2}>You will receive an e-mail in maximum 60 seconds</SoftTypography>
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" onChange={event => setEmailAccount(event.target.value)} />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" fullWidth
            onClick={handleResetAccountPassword}
          >
            send
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={3} textAlign="center">
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Already have an account?&nbsp;
          <SoftTypography
            component={Link}
            to="/authentication/sign-in"
            variant="button"
            color="info"
            fontWeight="bold"
            textGradient
          >
            Sign in
          </SoftTypography>
        </SoftTypography>
      </SoftBox>
    </IllustrationLayout>
  );
}
